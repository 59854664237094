module.exports = [{
      plugin: require('/codebuild/output/src052985490/src/canevsroadtrip/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145354849-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/codebuild/output/src052985490/src/canevsroadtrip/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('/codebuild/output/src052985490/src/canevsroadtrip/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
